<template>
  <div>
     <register-payment-other-table :fields="fields" :filters="filters" tab="by-cash"  />
  </div>
</template>

<script>
import RegisterPaymentOtherTable from "@/views/administration/views/register-payment/components/others/RegisterPaymentOtherTable.vue";
import fields from "@/views/administration/views/register-payment/data/register-payments-other/by-mail.fields";
import filters from "@/views/administration/views/register-payment/data/register-payments-other/by-mail.filters";
export default {
    components:{
        RegisterPaymentOtherTable
    },
    data(){
        return {
            fields,
            filters
        }
    }
}
</script>

<style>

</style>